import { render, staticRenderFns } from "./MPProducts.vue?vue&type=template&id=e4ecf13e"
import script from "./MPProducts.vue?vue&type=script&lang=js"
export * from "./MPProducts.vue?vue&type=script&lang=js"
import style0 from "./MPProducts.vue?vue&type=style&index=0&id=e4ecf13e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarketProductsMPSortby: require('/usr/src/app/components/Market/Products/MPSortby.vue').default,MarketProductsMPProductsShowMore: require('/usr/src/app/components/Market/Products/MPProductsShowMore.vue').default,MarketProductsMPProductPagination: require('/usr/src/app/components/Market/Products/MPProductPagination.vue').default,MarketMPSeoCategoryTags: require('/usr/src/app/components/Market/MPSeoCategoryTags.vue').default,MarketMPCategorySEO: require('/usr/src/app/components/Market/MPCategorySEO.vue').default})
