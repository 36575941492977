
import { scrollTo } from '@/utilities'
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'

export default {
  name: 'MPProducts',
  props: {
    bannerType: {
      type: String,
      default: '',
    },
    viewMode: {
      type: String,
      default: '',
    },
    listName: {
      type: String,
      default: null,
    },
    source: {
      type: String,
      default: '',
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    const { resolution } = storeToRefs(uiStore)
    return {
      marketStore,
      resolution,
    }
  },
  data() {
    return {
      page: 10,
    }
  },
  computed: {
    currentPage() {
      return this.$route.query.page
    },
    showPopularNow() {
      return (
        this.marketStore?.PopularNowProducts?.length &&
        (this.$route.name?.includes('brands') ||
          this.$route.name?.includes('tags') ||
          this.$route.name?.includes('categories'))
      )
    },
    showBannerPageDesktop() {
      if (!this.currentPage) {
        return 1
      }
      if (this.currentPage % 4 === 0) {
        return 4
      }
      if (this.currentPage % 4 === 3) {
        return 3
      }
      if (this.currentPage % 4 === 2) {
        return 2
      }
      if (this.currentPage % 4 === 1) {
        return 1
      }
      return 1
    },
    showBannerPageMobile() {
      if (!this.currentPage) {
        return 1
      }
      if (this.currentPage % 2 === 0) {
        return 2
      }
      return 1
    },
    showBanners() {
      return this.bannerType === 'max_discount'
    },
    showBannersProducts() {
      return (
        this.bannerType === 'products' ||
        this.bannerType === 'products_and_categories'
      )
    },
    showBrandLinks() {
      return this.resolution === 'md' || this.resolution === 'sm'
    },
    isMobile() {
      return this.resolution === 'sm'
    },
    isFilterSet() {
      const queries = JSON.parse(JSON.stringify(this.$route.query))

      if (Object.keys(this.$route.query).length) {
        if (Object.keys(this.$route.query).includes('page')) delete queries.page
        if (Object.keys(this.$route.query).includes('q[s]'))
          delete queries['q[s]']
        if (Object.keys(this.$route.query).includes('from_search'))
          delete queries.from_search
      }
      return Object.keys(queries).length
    },
    hasFoundProducts() {
      return this.isFilterSet
    },
    showProductsList() {
      if (this.$route.name?.includes('new-products')) {
        return this.marketStore.products.filter(
          item => item?.status !== 'out_of_stock',
        )
      } else {
        return this.marketStore.products
      }
    },
    productsLength() {
      return this.showProductsList.length || 0
    },
    isTitleSeoVisible() {
      return this.$route.name?.includes('categories')
    },
  },
  watch: {
    $route() {
      scrollTo(document.getElementsByClassName('Layout')[0], 0, 700)
    },
  },
  methods: {
    onPaginate(pageNum) {
      const query = {}
      const routeQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (Object.prototype.hasOwnProperty.call(routeQuery, 'page')) {
        routeQuery.page = +pageNum
      } else {
        query.page = pageNum
      }
      if (routeQuery?.per_page) routeQuery.per_page = routeQuery?.per_page
      if (this.$route.name?.includes('categories')) {
        this.$nextTick(() => {
          this.$router.push({
            path: this.localePath({
              name: 'categories-slug',
              query: { ...query, ...routeQuery },
            }),
          })
        })
      }
      if (this.$route.name?.includes('search')) {
        this.$nextTick(() => {
          this.$router.push({
            path: this.localePath({
              name: 'search-term',
              params: {
                term: this.$route.params.term,
              },
              query: { ...query, ...routeQuery },
            }),
          })
        })
      }
      if (this.$route.name?.includes('merchant-merchant')) {
        this.$nextTick(() => {
          this.$router.push({
            path: this.localePath({
              name: 'merchant-merchant',
              params: {
                merchant: this.$route.params.merchant,
              },
              query: { ...query, ...routeQuery },
            }),
          })
        })
      }
      if (this.$route.name?.includes('brands')) {
        this.$nextTick(() => {
          this.$router.push({
            path: this.localePath({
              name: 'brands-brand',
              params: {
                brand: this.$route.params.brand,
              },
              query: { ...query, ...routeQuery },
            }),
          })
        })
      }
      if (this.$route.name?.includes('mega-discounts')) {
        this.$nextTick(() => {
          this.$router.push({
            path: this.localePath({
              name: 'mega-discounts',
              query: { ...query, ...routeQuery },
            }),
          })
        })
      }
      if (this.$route.name?.includes('new-products')) {
        this.$nextTick(() => {
          this.$router.push({
            path: this.localePath({
              name: 'new-products',
              query: { ...query, ...routeQuery },
            }),
          })
        })
      }
      if (this.$route.name?.includes('tags')) {
        this.$nextTick(() => {
          this.$router.push({
            path: this.localePath({
              name: 'tags-tag',
              params: {
                tag: this.$route.params.tag,
              },
              query: { ...query, ...routeQuery },
            }),
          })
        })
      }
    },
    showBannersIsProducts(number_banners) {
      if (number_banners === 2) {
        if (this.resolution === 'lg' && this.productsLength > 3) {
          return true
        } else if (this.productsLength > 4) {
          return true
        } else {
          return false
        }
      }
      if (number_banners === 3) {
        if (this.resolution === 'lg' && this.productsLength > 6) {
          return true
        } else if (this.productsLength > 8) {
          return true
        } else {
          return false
        }
      }
      if (number_banners === 4) {
        if (this.resolution === 'lg' && this.productsLength > 9) {
          return true
        } else if (this.productsLength > 12) {
          return true
        } else {
          return false
        }
      }
      return false
    },
  },
}
