
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'

export default {
  name: 'MPSortBy',
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    const { resolution } = storeToRefs(uiStore)
    return {
      marketStore,
      uiStore,
      resolution,
    }
  },
  data() {
    return {
      options: [
        {
          label: this.$t('market.cheapest'),
          value: 'retail_price asc',
        },
        {
          label: this.$t('market.expensive'),
          value: 'retail_price desc',
        },
        {
          label: this.$t('market.popular'),
          value: this.getValuePopular(),
        },
        {
          label: this.$t('market.sortByDiscount'),
          value: 'discount desc',
        },
        {
          label: this.$t('market.new_items'),
          value: 'activated_at desc',
        },
      ],
      config: {
        dropdownClass: 'MPSortBy-Dropdown',
      },
    }
  },
  computed: {
    showDesktop() {
      return (
        this.resolution === 'lg' ||
        this.resolution === 'xxl' ||
        this.resolution === 'xl'
      )
    },
    selected: {
      get() {
        const query = this.$route.query['q[s]']
        return query
          ? this.marketStore.productsSortBy
          : this.defaultSelectedSort()
      },
      set(val) {
        this.marketStore.productsSortBy = val
      },
    },
  },
  beforeMount() {
    setTimeout(() => {
      if (
        this.$route.query['q[s]'] &&
        this.selected !== this.$route.query['q[s]']
      ) {
        this.selected = this.$route.query['q[s]']
      }
    }, 500)
  },
  methods: {
    contentSortBy(by) {
      const categories = this.marketStore.filters?.default?.find(
        filter => filter.field === 'categories.id',
      )
      if (
        (this.$route.params?.tag || this.$route.params?.term) &&
        categories?.buckets?.length > 1
      ) {
        this.uiStore.isPopupTagsCategoriesVisible = true
      } else {
        const options = {
          'retail_price asc': 'price_low_to_high',
          'retail_price desc': 'price_high_to_low',
          'category_popular_score desc': 'popular',
          'global_popular_score desc': 'popular',
          'id boosted_score': 'popular',
          'discount_score desc': 'popular',
          'discount desc': 'discount',
          'activated_at desc': 'newest',
        }
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({
            event: 'sorting_applied',
            choice: options?.[by] || '',
          })
        }
        const query = Object.assign({}, this.$route.query)
        query['q[s]'] = by
        if (by !== this.$route.query['q[s]']) {
          this.$router.replace({ query })
        }
      }
    },
    defaultSelectedSort() {
      if (this.$route.name?.includes('categories')) {
        return 'category_popular_score desc'
      } else if (this.$route.name?.includes('tag')) {
        return 'id boosted_score'
      } else if (this.$route.name?.includes('search')) {
        return 'id boosted_score'
      } else if (this.$route.name?.includes('mega-discounts')) {
        return 'discount_score desc'
      } else if (this.$route.name?.includes('new-products')) {
        return 'activated_at desc'
      } else {
        return 'discount_score desc'
      }
    },
    getValuePopular() {
      if (this.$route.name?.includes('categories')) {
        return 'category_popular_score desc'
      } else if (this.$route.name?.includes('tag')) {
        return 'id boosted_score'
      } else if (this.$route.name?.includes('search')) {
        return 'id boosted_score'
      } else {
        return 'discount_score desc'
      }
    },
  },
}
