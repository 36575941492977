
import vClickOutside from 'v-click-outside'
import { findFromList } from '@/utilities'

export default {
  name: 'USelect',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    inputPlaceholder: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    emptyText: {
      type: String,
      default: '',
    },
    reset: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    focusable: {
      type: Boolean,
      default: true,
    },
    valueField: {
      type: String,
      default: 'value',
    },
    inputClasses: {
      type: Array,
      default: () => ['px-2.5'],
    },
    labelField: {
      type: String,
      default: 'label',
    },
    blockOpen: {
      type: Boolean,
      default: false,
    },
    showTopPlaceholder: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      opened: false,
      input: '',
    }
  },

  computed: {
    selectedItem() {
      const selected = (this.items || []).find(
        item => item[this.valueField] === this.value,
      )
      return selected
    },

    label() {
      return (this.selectedItem && this.selectedItem[this.labelField]) || ''
    },

    filteredItems() {
      if (!this.items || !this.items.length) return []
      const input = this.input?.toLowerCase()
      return findFromList(this.items, input)
    },
  },

  methods: {
    handleScroll() {
      this.$emit('scroll')
    },
    isActive(item) {
      return this.selectedItem?.[this.valueField] === item?.[this.valueField]
    },

    onSearch(event) {
      // WARN: this.input is not reactive when using v-model in android 10-12, GKeyboard this will fix it.
      this.input = event.target.value
    },

    onClickReset(evt) {
      if (this.blockOpen) return
      this.$emit('change', null)
      this.$emit('reset')
      this.opened = false
    },

    onClickItem(item, index) {
      if (this.blockOpen) return
      this.$emit('change', item[this.valueField])
      this.$emit('select', {
        value: item[this.valueField],
        index,
        item,
      })
      this.opened = false
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'pickup_point_dropdown_select',
        })
      }
    },

    onClickSelectedBox() {
      if (this.blockOpen) return
      this.input = ''
      this.opened = !this.opened
      if (this.opened && this.searchable && this.focusable) {
        setTimeout(() => {
          this.$refs.inputRef.focus()
        }, 100)
      }
    },
    onClickOutside() {
      this.input = ''
      this.opened = false
    },
  },
}
