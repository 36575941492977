
import { mapState, storeToRefs } from 'pinia'
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'
import startObserverLoading from '@/mixins/startObserverLoading'

export default {
  name: 'MPSeoCategoryTags',
  mixins: [startObserverLoading],
  props: {
    viewMode: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    const { isMobile } = storeToRefs(uiStore)
    return {
      marketStore,
      isMobile,
    }
  },
  data() {
    return {
      limitation: true,
      isMobileView: false,
      hasSEOTags: false,
      waitingToLoad: true,
    }
  },

  async fetch() {
    if (this.$device.isCrawler) {
      const options = {
        'q[category_id_in]': this.marketStore?.product?.category_id,
        'q[tag_type]': 'seo',
        search_mode: 'category',
        'q[opaque_id]': this.$route?.fullPath,
      }
      const marketStore = useMarketStore()
      const resp = await marketStore.getSeoTags(options)
      this.hasSEOTags = resp && resp.length

      this.waitingToLoad = false
    }
  },
  computed: {
    ...mapState(useMarketStore, ['seoTags']),
    filterSeoTags() {
      const seoTags = this.seoTags || []
      return seoTags.filter(
        tag =>
          tag[`slugged_name_${this.$i18n.locale}`] !== this.$route.params.tag,
      )
    },
    volumeSeoTags() {
      // const seoTags = []
      // const seoTags_1 = this.seoTags?.filter(tag => tag.volume === 1)
      // const seoTags_2 = this.seoTags?.filter(tag => tag.volume === 2)
      // const seoTags_3 = this.seoTags?.filter(tag => tag.volume === 3)
      // const seoTags_0 = this.seoTags?.filter(tag => tag.volume === 0)
      // seoTags.push(...seoTags_1.slice(0, 2))
      // seoTags.push(...seoTags_2.slice(0, 3))
      // seoTags.push(...seoTags_3.slice(0, 10))
      // seoTags.push(...seoTags_0.slice(0, 5))
      if (this.limitation) {
        return this.seoTags?.slice(0, 15)
      } else {
        return this.seoTags
      }
    },
    limitedTags() {
      return this.volumeSeoTags
    },
    isShowAll() {
      return this.limitation && (this.seoTags || []).length > 15
    },
    isPageOne() {
      return this.marketStore.pagination?.page === 1
    },
    isOthersPage() {
      return (
        this.$route.path.includes('/product/') ||
        this.viewMode === 'products_and_categories'
      )
    },
    showSEOTags() {
      return this.isPageOne || this.isOthersPage
    },
    locale() {
      return this.$i18n?.locale
    },
  },
  watch: {
    isMobile(newV) {
      this.changeScreenSize()
    },
  },
  beforeMount() {
    this.changeScreenSize()
  },
  mounted() {
    if (!this.$device.isCrawler) {
      this.startObserverLoading(
        this.$refs.MPSEOCategoryTagsRef,
        this.startLoadingSEOTags,
      )
    }
  },
  methods: {
    async startLoadingSEOTags() {
      const options = {
        'q[category_id_in]': this.marketStore?.product?.category_id,
        'q[tag_type]': 'seo',
        search_mode: 'category',
        'q[opaque_id]': this.$route?.fullPath,
      }
      const marketStore = useMarketStore()
      const resp = await marketStore.getSeoTags(options)
      this.hasSEOTags = resp && resp.length

      this.waitingToLoad = false
    },
    onShowAllClick() {
      this.limitation = false
    },
    changeScreenSize() {
      // nextTick нужен, чтобы клиентский рендеринг отработал и не конфликтовал с серверным
      this.$nextTick(() => (this.isMobileView = this.isMobile))
    },
  },
}
